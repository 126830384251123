import React, { Fragment, useEffect, useRef, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import OffcanvasSearchbar from "../../components/offcanvas/searchbar/offcanvas-searchbar";
import SearchFields from "../../components/ServicesSearch/ServicesSearch";
import BookAppointmentForm from "../../components/forms/book-appointment-form/BookAppointmentForm";
import { services } from "../../data/data";
import AboutBookAppointment from "../../components/aboutus-mini/book-appointment";
import ServiceInvoice from "../../components/invoice/service-invoice";
import { formServiceSearchData } from "../../utils/helper";
import { Link } from "react-router-dom";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";

function Services() {
  let [showInvoice, setShowInvoice] = useState(false);
  const [serviceSearchData, setServiceSearchData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [webstoreReferred, setWebstoreReferred] = useState(false);
  const [queryParams, setQueryParams] = useState(null);
  const bookingFormRef = useRef(null);
  const [tabActiveIndex, setTabActiveIndex] = useState(1);
  const [serviceType, setServiceType] = useState("PHARM");
  const [location, setLocation] = useState(null);

  // Location
  Radar.initialize(process.env.REACT_APP_RADAR_PK);

  useEffect(() => {
    if (!location) {
      console.log("Geocoding Step 0");
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // Getting Location
          const { latitude, longitude } = position.coords;
          console.log(position);

          // Getting Postal Code
          Radar.reverseGeocode({
            latitude,
            longitude,
          })
            .then((result) => {
              console.log(result);
              // Setting Location
              setLocation({
                latitude,
                longitude,
                postalCode: result.addresses[0].postalCode,
              });
            })
            .catch((err) => {
              console.log("Error in reverse geocoding.");
              console.log(err);
              setLocation({
                latitude: 51.50735,
                longitude: -0.12776,
                postalCode: "WC2N 5DN",
              });
            });
          setLocation({ latitude, longitude });
        },
        (error) => {
          console.log("Error fetching location:", error);
          console.log("Setting location to London");
          setLocation({
            latitude: 51.50735,
            longitude: -0.12776,
            postalCode: "WC2N 5DN",
          });
        },
      );
    }
  }, [location]);

  // Query Params
  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    if (query) {
      if (query.get("webstore_referred")) {
        setWebstoreReferred(true);
        setQueryParams(query);
      } else {
        setWebstoreReferred(false);
        setQueryParams(null);
      }
    }
  }, []);

  useEffect(() => {
    if (webstoreReferred) {
      // Get Service Info
      formServiceSearchData(
        queryParams.get("srvc_id")?.toLowerCase(),
        queryParams.get("p_nbr"),
      ).then((resp) => {
        setServiceSearchData(resp);

        // Scroll
        bookingFormRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      });
    } else {
      setServiceSearchData(null);
    }
  }, [webstoreReferred]);

  // Use Effect
  useEffect(() => {
    setShowInvoice(false);
  }, []);

  // Handlers
  const handleServiceSearch = (selectionData) => {
    setServiceSearchData(selectionData);
  };

  const handleOrderData = (orderData) => {
    setOrderData(orderData);
  };

  const handlePaymentData = (paymentData) => {
    setPaymentData(paymentData);
    setShowInvoice(true);
    window.history.replaceState(null, null, "/book/services/invoice");
  };

  // BookFormProps
  let bookingProps = {
    serviceSearchData: serviceSearchData,
  };

  return (
    <Fragment>
      <Header />
      <div
        style={{
          backgroundImage: `url(assets/images/portrait-female-pharmacist-isolated-white-background_75891-3423.avif)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundAttachment: "fixed",
          overflow: "revert-layer",
        }}
      >
        <section className="bg-half-170 d-table w-100" id="home">
          <div className="bg-overlay bg-overlay-dark"></div>
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-xl-10">
                <div
                  className="heading-title text-center"
                  style={{ marginTop: 150 }}
                >
                  {/*<img src="assets/images/logo-icon.png" height="100" alt="" />*/}
                  <h4 className="heading fw-bold text-white title-dark mt-3 mb-4">
                    Book Your Appointments
                  </h4>
                  <p className="para-desc mx-auto text-white-50 mb-0">
                    Extensive network, highly rated practitioners, convenient
                    locations and tele-consultation options!
                  </p>
                  <div className="col-12 mt-0 mb-0 pb-0 pt-0">
                    <ul className="nav nav-pills nav-justified flex-column flex-sm-row shadow overflow-hidden bg-white-50 mt-3 mb-0 pb-0 rounded-0">
                      <li className="nav-item">
                        <Link
                          className={`${
                            tabActiveIndex === 1 ? "active" : ""
                          } nav-link rounded-0 fw-bolder`}
                          style={{ fontSize: "medium" }}
                          to={null}
                          onClick={() => setTabActiveIndex(1)}
                        >
                          Search
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`${
                            tabActiveIndex === 0 ? "active" : ""
                          } nav-link rounded-0 fw-bolder`}
                          style={{ fontSize: "medium" }}
                          to={null}
                          onClick={() => setTabActiveIndex(0)}
                        >
                          Explore
                        </Link>
                      </li>
                    </ul>
                    <SearchFields
                      serviceSearchData={handleServiceSearch}
                      tabActiveIndex={tabActiveIndex}
                      serviceType={serviceType}
                      location={location}
                      setLocation={setLocation}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section
        ref={bookingFormRef}
        className="section mt-0 pb-0"
        id="appointment"
      >
        <div className="container mt-0">
          <div className="row align-items-lg-end">
            {showInvoice ? (
              <ServiceInvoice data={paymentData} id="invoice" />
            ) : (
              [
                <AboutBookAppointment />,
                <BookAppointmentForm
                  data={bookingProps}
                  handleOrderData={handleOrderData}
                  handlePaymentData={handlePaymentData}
                />,
              ]
            )}
          </div>
        </div>
      </section>
      <section className="section mt-0 pb-5">
        <div className="container mt-0">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span
                  className="badge rounded-pill bg-soft-primary mb-3"
                  style={{ fontSize: "large" }}
                >
                  Our Medical Services
                </span>
                {/*<h4 className="title mb-4">Our Medical Services</h4>*/}
                <p className="text-muted mx-auto para-desc mb-0">
                  We pride on making your service appointment seamless, secure,
                  and reliable!
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {services.map((serv, index) => {
              return (
                <div className="col-xl-3 col-md-4 col-12 mt-5">
                  <div className="card features feature-primary bg-transparent border-0">
                    <div className="icon text-center rounded-md">
                      {serv.image ? (
                        <img
                          src={serv.imagePath}
                          className="avatar avatar-ex-small rounded"
                          alt=""
                        />
                      ) : (
                        <i className={`${serv.icon} h3 mb-0`}></i>
                      )}
                    </div>
                    <div className="card-body p-0 mt-3">
                      <Link to={"/home"} className="title text-dark h5">
                        {serv.name}
                      </Link>
                      <p className="text-muted mt-3">{serv.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      <OffcanvasSearchbar />
    </Fragment>
  );
}

export default Services;
