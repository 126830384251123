import React, { useEffect, useState } from "react";
import {
  getAllServices,
  getConsentForm,
} from "../../utils/referencedata.services";
import {
  getPractitionerService,
  searchServicesAndPharmacies,
} from "../../utils/pctmgmt.services";
import Pharmacies from "./Pharmacies";
import ServiceSelect from "./ServicesSelect";
import PostalCodeInput from "./PostalCodeInput";
import SearchButton from "./SearchButton";
import "./services-search.css";
import ServiceExplore from "./ServicesExplore";
import Radar from "radar-sdk-js";
import "radar-sdk-js/dist/radar.css";

function GPSearchFields({
  serviceSearchData,
  tabActiveIndex,
  serviceType,
  location,
  setLocation,
}) {
  Radar.initialize(process.env.REACT_APP_RADAR_PK);

  const getInitialSelection = () => "Select a Service";
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(getInitialSelection);
  const [selectedServiceObject, setSelectedServiceObject] = useState(null);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [consentTemplate, setConsentTemplate] = useState(null);
  const [postalCode, setPostalCode] = useState("HA8 7HF");
  const [showPharmacies, setShowPharmacies] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);

  useEffect(() => {
    if (location) {
      setPostalCode(location.postalCode);
    }
  }, [location]);

  useEffect(() => {
    if (postalCode) {
      Radar.autocomplete({ query: postalCode, layers: ["postalCode"] })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [postalCode]);

  useEffect(() => {
    getAllServices().then((response) => {
      setServices(
        response.data
          .sort((a, b) => a.serviceName.localeCompare(b.serviceName))
          .filter((srvc) => srvc.serviceSubCategory === "GP"),
      );
    });
  }, []);

  const handlePharmacySelection = (data) => {
    // Get Service Information
    let practitioner = data?.practitionerData;
    let p_nbr = practitioner?.p_nbr;
    data.practitionerData.business_nm = `${data.practitionerData.business_nm} - Dr. Morgan`;
    getPractitionerService(p_nbr, selectedServiceId).then((response) => {
      data.service = response.data;
      data.consentTemplate = consentTemplate;
      serviceSearchData(data);
    });
  };

  const resetSelection = () => {
    setSelectedService(getInitialSelection);
    setSelectedServiceObject(null);
    setSelectedServiceId(null);
    setConsentTemplate(null);
    setShowPharmacies(false);
    setPharmacies([]);
    serviceSearchData(null);
  };

  useEffect(() => {
    resetSelection();
  }, [tabActiveIndex]);

  const selectService = (e) => {
    setSelectedService(e.serviceName);
    setSelectedServiceObject(e);
    setShowPharmacies(false);
    setPharmacies([]);
    let service = services.find((serv) => serv.serviceName === e.serviceName);
    setSelectedServiceId(service.serviceId);

    if (service.templateInd === 1 && service.templateId) {
      getConsentForm(service.templateId).then((response) => {
        setConsentTemplate(response.data);
      });
    } else {
      setConsentTemplate(null);
    }
  };

  const searchPharmacies = () => {
    const payload = {
      component: "SEARCH",
      postalCode: postalCode,
      serviceNm: selectedService,
      serviceType: "Non-NHS",
    };

    searchServicesAndPharmacies(payload).then((response) => {
      const results = response.data;

      if (results.data.length > 0) {
        stackRankPharmacies(results.data, location).then((r) => {});
      }
    });
  };

  const stackRankPharmacies = async (pharms, location) => {
    try {
      // Filter out pharmacies with invalid coordinates
      const destinations = pharms
        .filter((obj) => obj.latitude && obj.longitude)
        .map((obj) => ({
          latitude: parseFloat(obj.latitude),
          longitude: parseFloat(obj.longitude),
        }));

      if (destinations.length === 0) {
        throw new Error("No valid pharmacy coordinates found.");
      }

      // Call Radar Matrix API
      const res = await Radar.matrix({
        origins: [location],
        destinations,
        mode: "car",
        units: "imperial",
      });

      // Map results and attach distances
      const results = pharms.map((obj, index) => ({
        ...obj,
        distance: res.matrix[0][index]?.distance?.value || 0, // Default to 0 if undefined
        distanceText: res.matrix[0][index]?.distance?.text || null,
      }));

      // Sort by distance
      const sortedResults = results.sort((a, b) => a.distance - b.distance);

      // Update state
      setShowPharmacies(true);
      setPharmacies(sortedResults);
    } catch (err) {
      console.error("Error in stackRankPharmacies:", err.message || err);
      setShowPharmacies(true);
      setPharmacies(pharms); // Fallback to the original list
    }
  };

  return (
    <div className="overflow-visible">
      <form className="rounded-0 text-start shadow p-4 bg-white-50">
        {tabActiveIndex === 1 ? (
          <div className="row align-items-center">
            <ServiceSelect
              services={services}
              selectService={selectService}
              selectedServiceObject={selectedServiceObject}
              isNhs={false}
            />
            <PostalCodeInput
              postalCode={postalCode}
              setPostalCode={setPostalCode}
              setLocation={setLocation}
            />
            <SearchButton searchPharmacies={searchPharmacies} />
          </div>
        ) : (
          <div>
            <div className="row align-items-center">
              <PostalCodeInput
                postalCode={postalCode}
                setPostalCode={setPostalCode}
                setLocation={setLocation}
              />
            </div>
            <div className="row align-items-center">
              <ServiceExplore
                services={services}
                selectService={selectService}
                selectedServiceObject={selectedServiceObject}
                resetSelection={resetSelection}
              />
            </div>
            <div className="row align-items-center">
              <SearchButton searchPharmacies={searchPharmacies} />
            </div>
          </div>
        )}
      </form>
      {showPharmacies && (
        <Pharmacies
          pharmacies={pharmacies}
          handleSelection={handlePharmacySelection}
          metadata={{
            serviceName: selectedService,
            serviceId: selectedServiceId,
            isNhs: false,
          }}
          serviceType={serviceType}
        />
      )}
    </div>
  );
}

export default GPSearchFields;
